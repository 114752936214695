<template>
  <div class="form-elements business-account-page">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loading">
          <template slot="header">
            <span class="title">
              {{ pageType === 'new' ? 'Add New Business' : 'Edit Business ' + (business ? '#'+business.id: '') }}
            </span>
          </template>
          <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="businessForm">
          <form @submit.prevent="handleSubmit(save)" v-if="business">
            <fieldset>
              <legend>User Information</legend>
              <div class="row">
                <div class="col-md-6">
                  <text-input name="Full Name" v-model="business.user.name" label="Full Name" validate="required"/>
                </div>
                <div class="col-md-6">
                  <text-input validate="required" name="CompanyName" v-model="business.name" label="Company Name"/>
                </div>

                <div class="col-md-6">
                  <text-input validate="required|email" name="email" v-model="business.user.email" label="Registration Email" @focusout.native="uniqEmailChk()" v-bind="{type: 'email'}" />
                </div>

                <div class="col-md-6 adjust" v-if="pageType != 'edit'">
                  <div class="control-label mt-2">Send Registration Email</div>
                  <vuestic-switch v-model="business.user.sendEmail" :offcolor="true" style="max-width: 500px;">
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Business Information</legend>
              <div class="row">
                <div class="col">
                  <phone-number-input name="DefaultBusinessPhoneNumber" v-model="business.properties.incomingCallsPhoneNumber" label="Default Business Number"/>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Widget Type</legend>
              <div class="row mt-0 select-hightlight">
                <div class="col-md-4 col-sm-12">
                  <div class="control-label">Widget Type</div>
                  <multiselect
                    v-model="widgetType"
                    :options="widgetTypeArr"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder=""
                    label="label"
                    track-by="label"
                    :preselect-first="true"
                    :show-labels="false"
                    :searchable="false"
                    :allowEmpty="false"
                  ></multiselect>
                </div>
                <div class="col-md-4 col-sm-12" v-if="business.widget_type == 3">
                  <div class="control-label">Default Communication Type</div>
                  <multiselect
                    v-model="communicationType"
                    :options="widgetCommunicationArr"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder=""
                    label="label"
                    track-by="label"
                    :preselect-first="true"
                    :show-labels="false"
                    :searchable="false"
                    :allowEmpty="false"
                  ></multiselect>
                </div>
              </div>
            </fieldset>
            <fieldset v-if="hasAgencyStripeEnabled">
              <legend>Plan Type</legend>
              <div class="row mt-2">
                <div class="col-md-4 col-sm-12" v-if="business.free_fee || business.user.stripe_status != 'active'">
                  <div class="control-label">Free Paid</div>
                  <vuestic-switch v-model="business.free_fee">
                    <span slot="trueTitle">Free</span>
                    <span slot="falseTitle">Paid</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-4 col-sm-12" v-if="!business.free_fee">
                  <div>
                    <div class="control-label">Subscription Plan</div>
                    <multiselect
                      v-model="subscriptionPlan"
                      :options="subscriptionPlans"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder=""
                      label="label"
                      track-by="label"
                      :preselect-first="true"
                      :show-labels="false"
                      :searchable="false"
                      :allowEmpty="false"
                    ></multiselect>
                  </div>
                  <div class="mt-4">
                    <button class="btn btn-primary" :disabled="disableCancelSubscription" @click.prevent="cancelSusbscription">
                      <atom-spinner v-if="loadingCancelSubscription" slot="loading" :animation-duration="1500" :size="14" color="#3578c6"/>
                      <span v-else>Cancel subscription</span>
                    </button>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 mt-3" v-if="!business.free_fee">
                  <div class="mt-0">
                    <p class="mb-0" v-if="trialPeriodDays"><b>Trial Period</b>: <span>{{ trialPeriodDays }} Days</span></p>
                    <p class="mb-0"><b>Status</b>: <span v-bind:class="{ 'text-danger': business.user.stripe_status=='canceled', 'text-green': business.user.stripe_status=='active' }">{{business.user.stripe_status}}</span></p>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="limit-group">
              <legend>Limits </legend>
              <div class="row mb-3">
                <div class="col-md-12">
                  <span>Number of Available Installs: <strong v-bind:class=" {'text-primary': availableInstallsCheck > 0, 'text-danger': !availableInstallsCheck} ">{{availableInstallsCheck}} </strong></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-6">
                  <!-- <div class="form-group">
                    <div class="input-group number-install-input-group">
                      <select v-bind:disabled = "hasAgencyStripeEnabled && !business.free_fee" v-model="installsCount" class="form-control" name="Number Of Installs">
                        <option disabled value="" selected>Select option</option>
                        <option v-bind:value="AvailableInstall" v-for="(AvailableInstall, index) in availableInstallsCheck" v-bind:key="index">{{ AvailableInstall }}</option>
                      </select>
                      <label for="availabe-install" class="control-label">Number Of Installs</label>
                      <i class="bar"></i>
                    </div>
                  </div> -->
                  <text-input v-bind:disabled = "hasAgencyStripeEnabled && !business.free_fee" validate="required" name="Number Of Installs" v-model="installsCount" label="Number of Installs" type="number"/>
                </div>

                <div class="col-md-3 col-sm-6">
                  <text-input v-bind:disabled = "hasAgencyStripeEnabled && !business.free_fee" validate="required" name="Number Of Users" v-model="usersCount" label="Number of Users" type="number"/>
                </div>
                <div class="col-md-3 col-sm-6">
                  <text-input v-bind:disabled = "hasAgencyStripeEnabled && !business.free_fee" validate="required" name="Number Of Automations" v-model="automationsCount" label="Number of Automations" type="number"/>
                </div>
                <div class="col-md-3 col-sm-6" v-if="widgetTypePhone">
                  <text-input v-bind:disabled = "hasAgencyStripeEnabled && !business.free_fee" validate="required" name="Number Of Calls" v-model="callsCount" label="Number of Calls Per Month" type="number"/>
                </div>
                <div class="col-md-3 col-sm-6" v-if="widgetTypeText">
                  <text-input v-bind:disabled = "hasAgencyStripeEnabled && !business.free_fee" validate="required" name="Number Of Messages" v-model="messagesCount" label="Number of Messages Per Month" type="number"/>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Features</legend>
              <div class="row">
                <div class="col-md-3 col-sm-12 mt-4" v-if="widgetTypePhone">
                  <div class="control-label mt-2">Enable Caller ID</div>
                  <vuestic-switch v-model="business.caller_id_enabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-3 col-sm-12 mt-4">
                  <div class="control-label mt-2">Enable Custom Twilio Number</div>
                  <vuestic-switch v-model="business.custom_number_enabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-3 col-sm-12 mt-4" v-if="widgetTypePhone">
                  <div class="control-label mt-2">Enable Form Integration</div>
                  <vuestic-switch v-model="business.form_enabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-3 col-sm-12 mt-4" v-if="widgetTypeText">
                  <div class="control-label mt-2">Enable Landline</div>
                  <vuestic-switch v-model="business.landline_enabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div v-if="agencyDepartmentEnabled" class="col-md-3 col-sm-12 mt-4">
                  <div class="control-label mt-2">Enable Department</div>
                  <vuestic-switch v-model="business.department_enabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-3 col-sm-12 mt-4">
                  <div class="control-label mt-2">Enable Sendgrid</div>
                  <vuestic-switch v-model="business.properties.sendgridEnabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div v-if="agencyZapEnabled" class="col-md-3 col-sm-12 mt-4">
                  <div class="control-label mt-2">Enable Zapier</div>
                  <vuestic-switch v-model="business.zapier_enabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div v-if="agencyRemoveBrandingEnabled" class="col-md-3 col-sm-12 mt-4">
                  <div class="control-label mt-2">Remove Branding</div>
                  <vuestic-switch v-model="business.properties.removeBranding" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
                <div class="col-md-3 col-sm-12 mt-4">
                  <div class="control-label mt-2">AI Integration</div>
                  <vuestic-switch v-model="business.properties.aiEnabled" :offcolor=true>
                    <span slot="trueTitle">ON</span>
                    <span slot="falseTitle">OFF</span>
                  </vuestic-switch>
                </div>
              </div>
            </fieldset>
            <fieldset class="mt-3">
              <legend>Properties</legend>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="checkbox abc-checkbox abc-checkbox-primary" style="padding-left: 10px">
                    <input type="checkbox" name="schedule" id="salesUserExtension" v-model="business.properties.hasSalesUserExtension">
                    <label for="salesUserExtension">
                      <span class="abc-label-text">Allow SaleUsers for Contact and Tag management</span>
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="row mt-5">
              <div class="col-md-12 d-flex justify-content-center">
                <button v-if="pageType == 'edit'" type="button" @click="impersonate()" class="btn btn-custom btn-default btn-primary" v-b-tooltip.hover title="Log In To Sub Account">
                  Login <i class="fa fa-sign-in"></i>
                </button>
                <router-link v-else class="btn btn-danger btn-primary" :to="{name: 'businessAccountsActive'}">
                  <span class="text-white">Back</span>
                </router-link>
                <button class="btn btn-primary ml-2" :disabled="invalid || processing">
                  <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
                  <span v-else>{{ pageType == 'edit' ? 'Update' : 'Submit' }}</span>
                </button>
              </div>
            </div>
          </form>
          </ValidationObserver>
        </vuestic-widget>
      </div>
    </div>
    <b-modal hide-footer v-model="isOpenModalRestrict">
      <div class="text-center modal-title-center">
        <h5 class="text-primary"><strong>No Enough Installs Available For Selected Plan!</strong></h5>
      </div>
      <div class="d-block text-center">
        <p>You don't have enough installs for selected plan. Please upgrade your subscription to add additional installs. Please reach out to <a class="text-primary" href="mailto:support@ringbot.io"><strong>support@ringbot.io</strong></a> if you have any questions.</p>
      </div>
      <b-button class="mt-3 close-btn modal-close" block @click="isOpenModalRestrict=false">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
  import TwilioNumberSelector from '../common/TwilioNumberSelector'
  import CreateInstall from "./Installs/Actions/CreateInstall";
  import { defaultBusiness } from '../../constants'
  // import PhoneNumber from "../forms/PhoneNumber"

  export default {
    name: "BusinessAccount",
    components: {
      TwilioNumberSelector,
      CreateInstall,
      // PhoneNumber
    },
    mounted() {
      this.init()
    },

    watch: {
      $route (to, from){
        if (to.name === 'businessAccountAdd')
        {
          this.pageType = 'new'
          this.init()
        }
      },
      'widgetType': {
        handler: function (newValue, oldValue) {
          if (newValue && newValue != oldValue)
          {
            this.business.widget_type = newValue['id']
            if ([1,2].includes(newValue['id']))
            {
              this.business.default_communication_type = newValue['id']
              this.communicationType = this.widgetCommunicationArr[newValue['id']-1]
            }
          }
        },
      },
      'communicationType': {
        handler: function (newValue, oldValue) {
          if (newValue && newValue != oldValue)
          {
            this.business.default_communication_type = newValue['id']
          }
        },
      },

      'subscriptionPlan': {
        handler: function (newValue, oldValue) {
          if (newValue && newValue != oldValue && this.hasAgencyStripeEnabled && !this.business.free_fee && !this.isOpenModalRestrict)
          {
            const planInstallCount = this.getSubscriptionMetaCount('Installs');
            if (planInstallCount > this.availableInstallsCheck)
            {
              this.isOpenModalRestrict = true
              this.subscriptionPlan = oldValue
            } else {
              this.installsCount = this.getSubscriptionMetaCount('Installs')
              this.callsCount = this.getSubscriptionMetaCount('Calls')
              this.messagesCount = this.getSubscriptionMetaCount('Messages')
              this.usersCount = this.getSubscriptionMetaCount('Users')
              this.automationsCount = this.getSubscriptionMetaCount('Automations')
            }
          }
        }
      },

      'subscriptionPlans': {
        handler: function (newValue, oldValue) {
          if (newValue && newValue != oldValue && this.hasAgencyStripeEnabled && this.business.subscription_plan_id)
          {
            this.subscriptionPlan = newValue.find(item => item.id === this.business.subscription_plan_id)
          }
        },
      },

      'business.free_fee': {
        handler: function (newValue, oldValue) {
          if (newValue != oldValue && this.hasAgencyStripeEnabled)
          {
            if (newValue){
              this.installsCount = this.business.number_of_installs
              this.callsCount = this.business.number_of_calls
              this.messagesCount = this.business.number_of_messages
              this.usersCount = this.business.number_of_users
              this.automationsCount = this.business.number_of_automations
            } else {
              this.installsCount = this.getSubscriptionMetaCount('Installs')
              this.callsCount = this.getSubscriptionMetaCount('Calls')
              this.messagesCount = this.getSubscriptionMetaCount('Messages')
              this.usersCount = this.getSubscriptionMetaCount('Users')
              this.automationsCount = this.getSubscriptionMetaCount('Automations')
            }
          }
        },
      }
    },
    computed: {
      ...mapGetters(["palette"]),
      ...mapState([
        'userFormData'
      ]),
      availableInstallsCheck(){
        let availableInstalls = this.availableInstalls
        if (this.pageType == 'edit')
        {
          availableInstalls = this.availableInstalls + this.alotedInstalls;
        }
        
        if(this.alotedInstalls > availableInstalls){
          return this.alotedInstalls
        }else{
          return availableInstalls
        }
      },
      availableInstalls() {
        return this.installState.available_installs;
      },
      widgetTypePhone() {
        return [1,3].includes(this.business.widget_type)
      },
      widgetTypeText() {
        return [2,3].includes(this.business.widget_type)
      },
      subscriptionPlans () {
        return this.plans.map(item => {
          return {
            id: item.id,
            label: item.name + ' - $' + item.amount,
            metadata: item.metadata,
            trial_period_days: item.trial_period_days,
          }
        })
      },
      user () {
        return this.$store.state.auth.user
      },
      agency () {
        return this.user && this.user.agency
      },
      disableCancelSubscription() {
        return this.loadingCancelSubscription || !this.business.subscription_plan_id || !(this.business && this.business.user && this.business.user.stripe_active)
      },
      hasAgencyStripeEnabled() {
        return this.$store.getters['auth/hasAgencyStripeEnabled']
      },
      agencyZapierEnabled() {
        return this.$store.getters['auth/agencyZapierEnabled']
      },
      agencyDepartmentEnabled() {
        return this.$store.getters['auth/agencyDepartmentEnabled']
      },
      agencyRemoveBrandingEnabled() {
        return this.$store.getters['auth/agencyRemoveBrandingEnabled']
      },
      trialPeriodDays() {
        if (!this.subscriptionPlan)
          return 0;
        const days = this.subscriptionPlan.trial_period_days;
        return days;
      },
      agencyZapEnabled() {
        return this.agency && this.agency.zap_enabled
      }
     },
    // @todo need to eventually remove these defauilt values
    data() {
      return {
        processing: false,
        isOpenModalRestrict: false,
        installsCount: 1,
        callsCount: 0,
        messagesCount: 0,
        usersCount: 0,
        automationsCount: 0,
        loadingCancelSubscription: false,
        pageType: 'new',
        installState: {
          available_installs: 0,
          total_installs: 0,
          used_installs: 0,
        },
        alotedInstalls: 0,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        loading: false,
        business: JSON.parse(JSON.stringify(defaultBusiness)),
        subscriptionPlan:{},
        widgetType: [],
        communicationType: [],
        widgetTypeArr: [
          {
            id: 1,
            label: 'Phone Only'
          },
          {
            id: 2,
            label: 'Text Only'
          },
          {
            id: 3,
            label: 'Phone & Text'
          }
        ],
        widgetCommunicationArr: [
          {
            id: 1,
            label: 'By Phone'
          },
          {
            id: 2,
            label: 'By Text'
          },
        ],
        plans: [],
      };
    },
    methods: {
      ...mapMutations([
        'ADD_USER_FORM_DATA'
      ]),

      getSubscriptionMetaCount(metaKey) {
        if (!this.subscriptionPlan || !this.subscriptionPlan.metadata)
          return 0;
        const item = this.subscriptionPlan.metadata.find(item => item.key == metaKey);
        if (!item) return 0;
        if (item.value > this.availableInstallsCheck)
        {

        }
        return item.value;
      },

      cancelSusbscription () {
        this.loadingCancelSubscription = true
        const businessUserId = this.business.user.id;
        this.$store.dispatch('subscription/cancelSubscription', {
            userId: businessUserId
          })
          .then((res) => {
            this.business.user.stripe_active = false;
            this.business.user.stripe_status = 'canceled';
            this.loadingCancelSubscription = false
          })
          .catch(errors => {
            this.loadingCancelSubscription = false
          })
      },

      init() {
        const agency_id = this.user.agency.id;
        this.$store.dispatch("agency/allPlans", { agency_id })
          .then(({ data }) => {
            this.plans = data.data
          })
          .catch(errors => {
          })

        if (!!this.$route.params.id) {
          this.loading = true;
          this.$store.dispatch('business/fetch', this.$route.params.id)
            .then(data => {
              this.loading = false;
              this.initBusiness(data.data);
            }).catch((e) => {
              this.loading = false;
              this.$router.push({ name: 'businessAccountsActive' });
            });
        } else 
        {
          this.business = JSON.parse(JSON.stringify(defaultBusiness));
          this.alotedInstalls = this.business.number_of_installs
          this.widgetType = this.widgetTypeArr[2];
          this.communicationType = this.widgetCommunicationArr[0];
          this.business.free_fee = true;
          this.subscriptionPlan = {}
          this.installsCount = this.business.number_of_installs
          this.callsCount = this.business.number_of_calls
          this.messagesCount = this.business.number_of_messages
          this.usersCount = this.business.number_of_users
          this.automationsCount = this.business.number_of_automations
        }

        axios.request({
          url:`/v1/agencies/${this.user.agency.id}/install-stats`,
          method:'POST',
        }).then((resp)=>{
          this.installState = resp.data.data;
        })
      },

      initBusiness(data) {
        this.business = data;
        this.alotedInstalls = this.business.number_of_installs
        this.$route.meta.displayName = `Edit ${this.business.name}`;
        this.pageType =  'edit'
        this.widgetType = this.widgetTypeArr.find(item => item.id == this.business.widget_type)
        this.communicationType = this.widgetCommunicationArr.find(item => item.id == this.business.default_communication_type)
        if (this.business.user.stripe_active){
          this.business.subscription_plan_id = this.business.user.stripe_plan;
        }
        else {
          this.business.subscription_plan_id = this.business.default_plan;
        }
        if (this.business.subscription_plan_id && this.subscriptionPlans)
        {
          this.subscriptionPlan = this.subscriptionPlans.find(item => item.id === this.business.subscription_plan_id)
        }
        this.installsCount = this.business.number_of_installs
        this.callsCount = this.business.number_of_calls
        this.messagesCount = this.business.number_of_messages
        this.usersCount = this.business.number_of_users
        this.automationsCount = this.business.number_of_automations

      },

      impersonate () {
        const businessId = this.business.id,
          businessUserId = this.business.user.id;
        sessionStorage.setItem("businessId", businessId);

        this.$store.state.app.isLoading = true;
        this.$store.dispatch("auth/impersonate", businessUserId).then(success => {
          this.$store.state.app.isLoading = false;
        }, error => {
          this.$store.state.app.isLoading = false;
        }).catch(error => {
          console.log('Error', error);
          this.$store.state.app.isLoading = false;
        });
        return false;
      },

      uniqEmailChk() {
        if (this.business.user.email)
        {
          const params = {
            email: this.business.user.email,
            user_id: this.business.user.id,
          }
          return this.$store.dispatch('auth/checkEmail', params)
          .then((res) => {
            return Promise.resolve(true);
          })
          .catch((e) => {
            if (e.response.data && e.response.data.errors) {
              this.$refs.businessForm.setErrors(e.response.data.errors)
            }
            return Promise.resolve(false);
          })
        } else {
          return Promise.resolve(false);
        }
      },

      async save() {
        if (!this.business.free_fee && (!this.subscriptionPlan || !this.subscriptionPlan.id))
        {
          this.showToast('Please select Subscription plan!', {icon: "fa-exclamation-triangle"});
          return false;
        }

        if (this.installsCount > this.availableInstallsCheck)
        {
          this.showToast('You can assign max ' + this.availableInstallsCheck + ' installs!', {icon: "fa-exclamation-triangle"});
          return false;
        }

        this.processing = true;
        
        const isValidEmail = await this.uniqEmailChk(this.business.user.email);
        if (isValidEmail)
        {
          let url = "/v1/business";
          let method = "post";
          if (!!this.business.id) {
            url = `/v1/business/${this.business.id}`;
            method = "put";
          }

          this.business.number_of_installs = this.installsCount;
          this.business.number_of_calls = this.callsCount;
          this.business.number_of_messages = this.messagesCount;
          this.business.number_of_users = this.usersCount;
          this.business.number_of_automations = this.automationsCount;
          this.business.subscription_plan_id = this.subscriptionPlan ? this.subscriptionPlan.id : '';

          axios.request({
              url,
              method,
              data: this.business
            })
            .then(({data}) => {
              this.processing = false;
              if (data.success) {
                if (!this.business.id) {
                  this.$store.dispatch('auth/impersonate', data.data.b_user_id)
                    .then(() => {
                      if (data.data.is_template) {
                        this.$router.push({ name: 'business.automation' })
                      } else {
                        this.$router.push({name: 'business.installs.edit', params: {id: data.data.install_id}})
                      }
                    })
                    .catch(() => {})
                } else {
                  this.initBusiness(data.data)
                }
              }
            }).catch((e) => {
              this.processing = false;
              console.log("Error", e);
            });
        } else {
          this.processing = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .business-account-page {
    .widget-type-select {
      input {
        border: 2px solid $rb-blue;
      }
    }
    .limit-group {
      select {
        height: 2.2rem!important;
      }
      select:disabled,
      input:disabled {
        background-color: $light-gray!important;
      }
    }
    .select-hightlight {
      .multiselect {
        border: 2px solid $rb-blue!important;
      }
      .multiselect__content-wrapper {
        outline: 2px solid $rb-blue!important;
        border: none!important;
        border-radius: 0!important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  select.form-control{
    width: 100%;
    background: none !important;
    border: .0625rem solid #e7e7e7;
    border-bottom: none;
    border-radius: .3rem;
  }
  select.form-control:focus{
    outline: none !important;
    box-shadow: none !important;
  }
  .number-install-input-group {
    margin-top: 20px;
  }
  legend {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 10px;
  }
  div.control-label {
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin-bottom: 3px;
  }
  /* theme doesn't have labels for the switches .. have to reposition whole column to match designs */
  div.adjust {
    top: -0.2rem;
    left: 0;
    position: relative;
  }
</style>
